// container
$container-max-widths: (
  // https://getbootstrap.jp/docs/5.0/layout/containers/#sass
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  // xxl: 1320px
);

// 日本語に最適化
$font-family-sans-serif:  -apple-system, 'BlinkMacSystemFont', 'Helvetica Neue', Helvetica, 'Arial', 'ヒラギノ角ゴ ProN W3', 'Hiragino Kaku Gothic ProN', 'メイリオ', Meiryo, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
$line-height-base: 1.65;

@import 'npm:bootstrap/scss/bootstrap.scss';

a {
  color: #236b81;
  &:hover {
    color: rgba(#236b81, 0.7);
    text-decoration: none;
  }
}

button#openMenuBtn:not(:hover) {
  background-color: rgba(white, 0.7);
}

#index {
  section {
    &:not(:last-child) {
      border-bottom: 1px solid $border-color;
    }
    &:nth-child(2n) {
      background: $light;
    }
  }

  section#top {
    .card:hover {
      opacity: 0.5;
    }
  }

  section#news {
    .card:hover {
      opacity: 0.5;
    }
  }

  section#profile {
    table {
      th, td {
        padding-bottom: 1.5rem;
        vertical-align: top;
      }
      th {
        padding-right: .5rem;
        @include media-breakpoint-up(md) { padding-right: 1.5rem; }

      }
      td {
        padding-left: .5rem;
        @include media-breakpoint-up(md) { padding-left: 1.5rem; }

      }
      th {
        min-width: 5em;
        @include media-breakpoint-up(md) { min-width: 10em; }
        text-align: right;
        border-right: 1px $border-color solid;
      }
    }
  }

  section#careers {
    h4 {
      font-weight: bold;
    }
  }
}

#news {
  h1, h2 {
    line-height: $line-height-base;
  }
}

@import '/_fontello/css/fontello.css';
